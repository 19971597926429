<template>
  <xlsx-workbook>
    <xlsx-sheet
      :sheet-name="name"
      :collection="data"
    />
    <xlsx-download :filename="(filename || ('abc-data-' + (new Date()).toISOString())) + '.xlsx'" :download="DownloadData">
      <v-btn depressed block small color="primary" class="white--text text-capitalize rounded-pill" @click.prevent="DownloadData">
        Download
      </v-btn>
    </xlsx-download>
  </xlsx-workbook>
</template>

<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue-xlsx'
export default {
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  props: {
    filename: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    DownloadData (d) {
      console.log(d)
    }
  }
}
</script>
